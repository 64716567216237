"use client";

import { Button } from "@/components/ui/button";
import Link from "next/link";
import { useState, useEffect } from "react";
import Image from "next/image";
import { IndustryCard } from "@/components/IndustryCard";
import { Star } from "lucide-react";

interface CenteredHeroSectionProps {
  tagline?: string;
  title: string;
  description: React.ReactNode;
  ctaText: string;
  ctaLink: string;
  backgroundVideo: string;
  backgroundImage: string;
  showIndustryCards: boolean;
  secondaryCtaText?: string;
  secondaryCtaLink?: string;
}

export function CenteredHeroSection({
  tagline,
  title,
  description,
  ctaText,
  ctaLink,
  backgroundVideo,
  backgroundImage,
  showIndustryCards,
  secondaryCtaText = "Watch demo video",
  secondaryCtaLink = "#video-section",
}: CenteredHeroSectionProps) {
  const [videoLoaded, setVideoLoaded] = useState(false);

  useEffect(() => {
    const video = document.querySelector("video");
    if (video) {
      video.addEventListener("loadeddata", () => setVideoLoaded(true));
      return () =>
        video.removeEventListener("loadeddata", () => setVideoLoaded(true));
    }
  }, []);

  return (
    <section className="relative overflow-hidden py-36 md:py-56">
      {/* <div className="absolute inset-0 w-full h-full">
        {!videoLoaded && (
          <Image
            src={backgroundImage}
            alt="WhisperIt AI-powered software background"
            fill
            className="object-cover"
            priority
            sizes="100vw"
          />
        )}
        <video
          autoPlay
          loop
          muted
          playsInline
          onLoadedData={() => setVideoLoaded(true)}
          className="absolute inset-0 w-full h-full object-cover"
        >
          <source src={backgroundVideo} type="video/mp4" />
        </video>
      </div> */}
      <div className="absolute inset-0 bg-white bg-opacity-40"></div>
      <div className="container relative z-10 mx-auto max-w-4xl px-4 text-center sm:px-6 lg:px-8">
        {tagline && (
          <Link
            href={ctaLink}
            className="mb-16 inline-block text-sm tracking-wide text-gray-600 transition duration-300 hover:text-gray-800 md:mb-20"
          >
            {tagline}
          </Link>
        )}

        <h1 className="mb-10 font-serif text-4xl font-bold leading-tight tracking-tight md:mb-12 md:text-5xl lg:text-6xl">
          {title}
        </h1>

        <p className="mb-20 text-2xl font-medium leading-relaxed tracking-wide text-gray-800 md:mb-28 md:text-3xl">
          {description}
        </p>

        {/* mini testimonials */}
        <div className="mb-24 flex justify-center md:mb-32">
          <div className="inline-block">
            <div className="mb-4 flex -space-x-4">
              <Image
                className="h-12 w-12 rounded-full border-2 border-white"
                src="/testimonials/beatrice-thouvenin.webp"
                alt="Beatrice Thouvenin"
                width={48}
                height={48}
              />
              <Image
                className="h-12 w-12 rounded-full border-2 border-white"
                src="/testimonials/benjamin-behr.webp"
                alt="Benjamin Behr"
                width={48}
                height={48}
              />

              <Image
                className="h-12 w-12 rounded-full border-2 border-white"
                src="/testimonials/philippe-gaemperle.webp"
                alt="Philippe Gaemperle"
                width={48}
                height={48}
              />
              <Image
                className="h-12 w-12 rounded-full border-2 border-white"
                src="/testimonials/stephan-schott.webp"
                alt="Stephan Schott"
                width={48}
                height={48}
              />
              <Image
                className="h-12 w-12 rounded-full border-2 border-white"
                src="/testimonials/jean-philippe-heim-avocat-lawyer-lausanne-switzerland.png"
                alt="Philippe Heim"
                width={48}
                height={48}
              />
              <Image
                className="h-12 w-12 rounded-full border-2 border-white"
                src="/testimonials/carlo-ceccarelli.webp"
                alt="Carlo Ceccarelli"
                width={48}
                height={48}
              />
            </div>
            <div className="flex items-center justify-start">
              {Array.from({ length: 5 }).map((_, i) => (
                <Star
                  key={i}
                  className="h-5 w-5 fill-current text-yellow-400"
                />
              ))}
              <span className="ml-3 text-sm tracking-wide text-gray-600">
                <span className="text-md bg-yellow-100 px-2 py-1">
                  Trusted by 170+ leading professionals
                </span>{" "}
              </span>
            </div>
          </div>
        </div>

        <div className="flex flex-col items-center justify-center gap-6 sm:flex-row">
          <Button
            size="lg"
            className="w-full px-12 py-4 text-base font-medium tracking-wide transition duration-300 hover:scale-105 sm:w-auto"
            onClick={() => window.open(ctaLink, "_self")}
          >
            {ctaText}
          </Button>
          <Button
            size="lg"
            className="w-full border border-gray-300 bg-transparent px-12 py-4 text-base font-medium tracking-wide text-black shadow-none transition duration-300 hover:scale-105 hover:bg-gray-50 sm:w-auto"
            onClick={() => window.open(secondaryCtaLink, "_self")}
          >
            {secondaryCtaText}
          </Button>
        </div>
      </div>

      {showIndustryCards && (
        <section className="container mx-auto mt-24 px-4">
          <div className="mx-auto grid max-w-4xl grid-cols-1 gap-8 md:grid-cols-3">
            <IndustryCard
              title="LEGAL"
              image="/home/legal.jpeg"
              link="/legal"
            />
            <IndustryCard
              title="HEALTHCARE"
              image="/home/medical.jpeg"
              link="/medical"
            />
            <IndustryCard
              title="OTHERS"
              image="/home/other-industry.jpeg"
              link="#"
            />
          </div>
        </section>
      )}
    </section>
  );
}
